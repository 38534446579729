<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h3 class="white--text headline mb-4">ANZCRO’s <b>Book With Confidence</b> Policy</h3>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="home-section-about">
      <v-flex>
        <v-card flat tile>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 pt-5>
                <p>For all new bookings between 01 September 2020 – 01 September 2022:</p>
                <ol class="mb-5">
                  <li class="mb-3">
                    <p>In the event Covid related travel restrictions are imposed by government (State or National), and travel is impacted on the booked travel dates, ANZCRO will provide a full refund (less supplier and 3rd party fees);</p>
                  </li>
                  <li class="mb-3">
                    <p>Refunds may be issued in 21 days;</p>
                  </li>
                  <li class="mb-3">
                    <p>In the event of cancellations unrelated to Covid restrictions, ANZCRO’s standard cancellation policy applies.</p>
                  </li>
                </ol>
              </v-flex>              
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>


  </v-container>
</template>

<script>
export default {
  data: () => ({

  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.about-hero {
  height: 70vh;
}

.about-hero .v-image__image {
  background-attachment: fixed;
}

.about-intro {
  margin-top: 0 !important;
  margin-bottom: 36px !important;
}

.hero-title h1,
.hero-title h3 {
  line-height: 1.2 !important;
}

.about-mission {
  background-color: #30302f;
  background-image: url('~@/assets/img/bg_tile_grey_waves_2.png');
  background-repeat: repeat;
  background-size: 50px 51px;
}

.about-mission .v-card {
  background-color: transparent !important;
}

@media (max-width: 599px) {
  .v-timeline--values:before {
    left: 18px;
  }
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right),
  .v-timeline--dense .v-timeline-item--left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .v-timeline--values .v-timeline-item__dot--small {
    left: 7px;
  }
  .v-timeline--values .v-timeline-item__body {
    max-width: calc(100% - 64px);
  }
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:before,
  .v-timeline--values .v-timeline-item--left .v-card:before,
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:after,
  .v-timeline--values .v-timeline-item--left .v-card:after {
    right: initial;
    left: -10px;
    -webkit-transform: none;
    transform: none;
  }
}

@media (min-width: 600px) {
  .about-hero {
    height: 60vh;
  }
  .hero-title .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }
  .about-intro p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .v-timeline--values .v-timeline-item {
    padding-bottom: 0;
    margin-bottom: -24px;
  }
  .v-timeline--values .v-timeline-item:last-of-type {
    margin-bottom: 0;
  }
}

@media (min-width: 960px) {
}
</style>
