<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-deals-lake-matheson-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Deals</h1>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-xl fill-height>
          <v-layout row wrap fill-height my-0>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content" color="transparent">
                <v-layout row wrap my-0>
                  <v-flex x12 sm6 lg6 pt-0 pb-4 v-for="(deal, n) in deals" :key="n">
                    <v-card
                      tile
                      class="pa-0 elevation-0  dash-rounded"
                      :href="deal.link"
                    >
                      <v-img
                        :src="deal.thumbnail"
                        gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.35)"
                      >
                        <v-container fill-height>
                          <v-layout dark column fill-height justify-space-between>
                            <v-flex text-xs-center class="home-hero-cta deal-title white--text display-1 font-weight-medium
                            "><img :src="deal.art" :alt="deal.title" class="deal-art" :width="deal.width" :height="deal.height" /></v-flex>
                          </v-layout>
                        </v-container>
                      </v-img>
                      <v-card-text class="pa-4 d-block">
                        <span class="title d-block mb-2">{{deal.title}}</span>
                        <span class="subheading d-block grey--text">{{deal.description}}</span>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    deals : [
      {
        title: 'Motorhome Madness',
        description: "WIN 7 Days Motorhome Hire valued over $2,500*",
        link: '/deals/motorhome-madness/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deals-collection-motorhomes.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/motorhome-madness-stacked.png',
        width: 300,
        height: 210
      },
      {
        title: 'Australia & New Zealand Hot Offers',
        description: "Unbeatable savings across Australia & New Zealand",
        link: '/deals/au-nz-hot-offers/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/AU-NZ-Hot-Offers-Deal-Thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/anz-hot-offers-stacked.png',
        width: 300,
        height: 210,
      },
      {
        title: 'Coach Tours of New Zealand',
        description: "A collection of some of New Zealand's favourite Guided Coach Tours",
        link: '/deals/new-zealand-coach-tours/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/Coach-Tours-Thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/Overlay.png',
        width: 300,
        height: 210,
      },
      {
        title: 'New Zealand Highways Collection',
        description: "Discover South Island's most popular themed road-trips.",
        link: '/deals/new-zealand-highways/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/deal-collection-nz-highways-chc.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/nz-highways-collection-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Iconic New Zealand Itineraries',
        description: "Unforgettable journeys through NZ's breathtaking landscapes.",
        link: '/deals/iconic-new-zealand/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/Iconic-NZ-Thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/iconic-nz-itineraries-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Warm Up in Australia',
        description: "Follow the sun with our latest tropical holiday collection",
        link: '/deals/warm-up-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deals-collection-warm-up-australia-2023.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/warm-up-in-australia-2024-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Bundaberg | Southern Great Barrier Reef',
        description: "Welcome to the town the turtles call home and beach meets reef.",
        link: '/deals/southern-great-barrier-reef/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/Bundaberg-SGBR-Thumbnaiil-1280x980-1.png',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/bundaberg-southern-gbr-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'South Australia | Travel. Our Way.',
        description: "Easy to remember and hard to forget.",
        link: '/deals/south-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-collection-south-australia-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/south-australia-travel-our-way-v2-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'New Zealand Winter Holiday Collection',
        description: "Including 5 Iconic Ski Fields and Early Bird Lift Pass Specials.",
        link: '/deals/new-zealand-ski-deals/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-collection-whc-2024.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/new-zealand-winter-holiday-collection-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Great Rail Journeys of New Zealand',
        description: "Climb aboard one of New Zealand’s Great Rail Journeys.",
        link: '/deals/rail-journeys/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deals-collection-great-rail-journeys-nz.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'The Snow Highway',
        description: "Discover the South Island’s freshest ski adventure.",
        link: '/deals/snow-highway/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deals-collection-snow-highway.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/new-zealand-snow-highway-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Great Aussie Walking Adventures',
        description: "Uncover the wonders of Australia step by step.",
        link: '/deals/walking-adventures/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/11/aus-walk-thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/11/great-aussie-walking-adventures_graphic-960x600-1.png',
        width: 240,
        height: 150
      },
      {
        title: 'Ultimate Luxury Holidays of Australia',
        description: "Indulgent relaxation in pristine Australian wilderness.",
        link: '/deals/luxury-holidays-au/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/Ultimate-Luxury-Holidays-AU_Thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/Ultimate-Luxury-Holidays-AU_graphic-960x600-1.png',
        width: 240,
        height: 150
      },
      {
        title: 'Ultimate Luxury Holidays of New Zealand',
        description: "Experience exquisite luxury in the most remarkable landscapes.",
        link: '/deals/luxury-holidays-nz/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/ult-lux-holidays-new-zealand_thumbnail-1280x980-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/ult-lux-holidays-new-zealand_graphic-960x600-1.png',
        width: 240,
        height: 150
      },
      {
        title: 'South Pacific Island Paradise',
        description: "Find paradise in the South Pacific with these special offers.",
        link: '/deals/south-pacific/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deals-collection-south-pacific-aud-4.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/south-pacific-island-paradise-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Most Popular New Zealand Road Trips',
        description: "Discover a selection of our most popular NZ self-drive holidays.",
        link: '/deals/popular-new-zealand-road-trips/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deals-collection-motorhomes.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/most-popular-new-zealand-road-trips-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Backyard Bargains',
        description: "Get a great deal on a holiday in your own backyard.",
        link: '/deals/australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/09/deals-collection-backyard-bargains-aud.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/australia-backyard-bargains-2022-stacked.png',
        width: 240,
        height: 200
      },
      {
        title: 'Paradise found in Lord Howe & Norfolk Island',
        description: "Only a short flight from Australia’s East Coast, find paradise in Lord Howe Island and Norfolk Island.",
        link: '/deals/lord-howe-norfolk-island/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deals-collection-norfolk.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/lord-howe-norfolk-island-stacked.png',
        width: 240,
        height: 150
      },
      /*
      {
        title: 'Gardens of New Zealand',
        description: "Your guide to the best New Zealand garden holidays of 2023 including 6 unique packages.",
        link: '/deals/gardens-new-zealand/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/deals-collection-gardens.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/gardens-of-new-zealand-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Explore Christchurch & Canterbury',
        description: "A revitalised city in the heart of New Zealand.",
        link: '/deals/christchurch/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/deals-collection-explore-chc.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/explore-chc-canterbury-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Discover the Bay of Plenty',
        description: "Home to waterfront cities, sleepy beachside towns and rural spots that offer a true Kiwi experience.",
        link: '/deals/bay-of-plenty/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deals-collection-bay-of-plenty.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/discover-the-bay-of-plenty-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'NZ Book Package Collection',
        description: "A collection of 12 packages as seen in our New Zealand Book 2022-2024.",
        link: '/deals/new-zealand-book/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-collection-nz-book.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/nz-book-package-collection-stacked-v2.png',
        width: 240,
        height: 150
      },
      {
        title: 'Signature Australian Experiences Collection',
        description: "A carefully curated selection of Australia’s most outstanding holidays.",
        link: '/deals/signature-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deals-collection-signature-australia.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/signature-australian-experiences-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: '2023 New Zealand Coach Tours',
        description: "Save up to 10% on New Zealand Coach Tours in our Black Friday Cyber Sale. Offer extended to Friday 23 December 2022",
        link: '/deals/new-zealand-coach-tours/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/deals-collection-black-friday-coach.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/new-zealand-coach-tours-black-friday-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Secrets of Hurunui',
        description: "Discover endless reasons to visit South Island’s hidden gem.",
        link: '/deals/hurunui/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deals-collection-hurunui.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/secrets-of-hurunui-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Picturesque NZ City Breaks',
        description: "Enjoy a charming getaway in one of New Zealand’s beautiful cities.",
        link: '/deals/nz-city-breaks/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/deals-collection-nz-city-breaks-22-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/new-picturesque-city-breaks-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australia’s Nature Coast Drive',
        description: "Get on the road and embark on a Queensland journey of natural beauty.",
        link: '/deals/australia-nature-coast-drive/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-australias-nature-coast-drive-2.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/australias-nature-coast-drive-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Backyard Bargains',
        description: "Get a great deal on a 2022 holiday in your own backyard.",
        link: '/deals/australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/09/deals-collection-backyard-bargains-aud.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/09/australia-backyard-bargains-2022-stacked.png',
        width: 240,
        height: 200
      },  
      {
        title: 'Rotorua - Always Original',
        description: "Fall in love with the North Island’s beloved cultural hotspot.",
        link: '/deals/rotorua/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deals-collection-rotorua.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/rotorua-always-original-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Iconic Bucket List Adventures',
        description: "The holiday you’ve been waiting your whole life to take.",
        link: '/deals/australia-bucket-list/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deals-collection-australia-bucket-list-aud.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/new-iconic-aussie-bucket-list-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Spotlight on Queenstown',
        description: "So much to see and do in the heart of the South Island.",
        link: '/deals/queenstown/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/deals-collection-spotlight-on-queenstown.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/spotlight-on-queenstown-stacked.png',
        width: 240,
        height: 200
      },
      {
        title: 'Spotlight on Waikato',
        description: "Discover the magic of the mighty Waikato.",
        link: '/deals/waikato/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deals-collection-waikato.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/spotlight-on-waikato-stacked-2.png',
        width: 240,
        height: 200
      },
      {
        title: 'Hot Offers on Holidays at Home (Australia)',
        description: "Pair a half-price flight with one of these 20 holiday packages.",
        link: '/deals/holidays-at-home/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deals-collection-half-price-flight-packages.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/hot-offers-on-holidays-at-home-stacked.png',
        width: 240,
        height: 150
      },*/
    ]
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.deal-title span{
  display:block;
  max-width: 75%;
}
</style>
