<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2024/01/hero-south-australia-travel-our-way-1-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.35) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/01/south-australia-travel-our-way-v2-stacked.png" alt="South Australia - Travel Our Way" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Bethany Wines, Barossa</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Easy to remember and hard to forget.</span>
                      <p>A visit to South Australia is more than a holiday. It’s a place to explore, to immerse yourself in the rugged beauty of dramatic coastlines, to get lost in the wilderness and to embrace being far away from the every day. Explore nature lover’s paradise on Kangaroo Island, dive into Australia’s seafood capital in Port Lincoln, taste the best wine in the world in the Barossa, or be mesmerised by the magenta majesty of the Pink Lakes in Lochiel. Each of these four spectacular South Australia packages can be customised, so you can truly travel your way.</p>
                      <!--<p>Check out these fantastic holiday packages, and as always, all packages can be customised.</p>-->
                    </v-card-text> 
                  </v-card>                 
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <v-layout row wrap align-center>
                        <v-flex grow>
                          <span class="headline font-weight-medium d-block">{{section.title}}</span>
                        </v-flex>
                        <v-flex shrink v-if="section.resources">
                          <v-btn v-for="(btn, btnindex) in section.resources" :key="btnindex" small class="card-with-border elevation-0 mr-0" :color="kiwiGreen" :href="btn.link" target="_blank">{{btn.label}} <v-icon color="white" size="14" class="ml-1">launch</v-icon></v-btn>
                        </v-flex>
                      </v-layout>                    
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                            <v-tooltip top v-if="deal.sm_tile">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                              </template>
                              <span>View Social Media Tile</span>
                            </v-tooltip>
                            <v-tooltip top v-if="deal.itinerary">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                              </template>
                              <span>View Flyer</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          resources: null,
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-taste-of-sa.jpg',
              name : '4 Day Taste of South Australia',
              price : '549',
              complement : 'per person (twin share)',
              code : 'ANZ244DTSA',
              location : 'Adelaide (ADL)',
              valid: '29 Apr - 28 Sep 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/Travel-Our-Way-South-Australia-AUD-Flyer-1.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-pink-lake-sa.jpg',
              name : '4 Day Pink Lake Glamping',
              price : '949',
              complement : 'per person (twin share)',
              code : 'ANZ244DPLG',
              location : 'Adelaide (ADL)',
              valid: '29 Apr - 30 Nov 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/Travel-Our-Way-South-Australia-AUD-Flyer-1.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-kangaroo-i-sa.jpg',
              name : '4 Day Kangaroo Island Getaway',
              price : '969',
              complement : 'per person (twin share)',
              code : 'ANZ244DKIG',
              location : 'Adelaide (ADL)',
              valid: '01 May - 30 Oct 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/Travel-Our-Way-South-Australia-AUD-Flyer-2.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-port-lincoln-sa.jpg',
              name : '5 Day Port Lincoln Escape',
              price : '1589',
              complement : 'per person (twin share)',
              code : 'ANZSA5DPLE',
              location : 'Adelaide (ADL)',
              valid: '30 Apr - 28 Nov 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/Travel-Our-Way-South-Australia-AUD-Flyer-2.pdf',
              sm_tile : null,
              call : false
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  