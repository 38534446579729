<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/AU-NZ-Hot-Offers-Banner-3540x1390-1-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/anz-hot-offers-stacked.png" alt="Australia & New Zealand Hot Offers" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                    <small><!--Twelve Apostles, Great Ocean Road - Victoria--></small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Unbeatable savings across Australia & New Zealand</span>
                      <p>Discover unbeatable offers with these incredible holiday packages for Australia and New Zealand. Immerse yourself in the golden sands of the Sunshine Coast, unwind with a unique stay at a PurePod, or enjoy a vibrant city break. Whether you're seeking relaxation or excitement, these hot offers promise a journey like no other.</p>
                      <p>Hurry, these packages and savings are only available for a short period of time - so book now!</p>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
            title : 'Australia',
            deals : [
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Escape-to-Sunny-Coast-136x136-1.jpg',
                    name : '4 Day Escape to the Sunny Coast',
                    price : '499',
                    complement : 'per person (twin share)',
                    code : 'ANZ244DESSC',
                    location : 'Sunshine Coast (MCY)',
                    valid: '01 Aug 2024 - 28 Mar 2025',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Escape-to-Sunshine-Coast-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Escape-to-the-Sunny-Coast-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Whitsunday-Cruise-136x136-1.jpg',
                    name : '5 Day Whitsunday Explorer',
                    price : '1299',
                    complement : 'per person (twin share)',
                    code : 'ANZ245DWE',
                    location : 'Proserpine (PPP)',
                    valid: '01 Aug 2024 - 07 Dec 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/5-Day-Whitsunday-Explorer-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/5-Day-Whitsunday-Explorer-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Sal-Salis-Stay-Save-136x136-1.jpg',
                    name : '4 Day Sal Salis Stay & Save',
                    price : ' 1789.00 ',
                    complement : 'per person (twin share)',
                    code : 'CONTACT US TO BOOK',
                    location : 'Exmouth (EXM)',
                    valid: '01 Aug 2024 - 08 Nov 20245',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Sal-Salis-Stay-Save-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Sal-Salis-Stay-Save-AUD-scaled.jpg'
                },
            ],
        },
        {
            title: 'New Zealand',
            deals: [
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Christchurch-Escape-136x136-1.jpg',
                    name : '4 Day Christchurch Escape',
                    price : '459',
                    complement : 'per person (twin share)',
                    code : 'ANZ244DCE',
                    location : 'Christchurch (CHC)',
                    valid: '10 Jul 2024 - 31 Oct 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Christchurch-Escape-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Christchurch-Escape-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Dunedin-Escape-136x136-1.jpg',
                    name : '4 Day Dunedin Escape',
                    price : ' 499',
                    complement : 'per person (twin share)',
                    code : 'ANZ244DCE',
                    location : 'Dunedin (DUD)',
                    valid: '10 Jul 2024 - 31 Oct 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/4-Day-Dunedin-Escape-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Dunedin-Escape-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Queenstown-Escape-136x136-1.jpg',
                    name : '4 Day Queenstown Escape',
                    price : ' 549',
                    complement : 'per person (twin share)',
                    code : 'ANZ244DQW',
                    location : 'Queenstown (ZQN)',
                    valid: '10 Sep 2024 - 31 Oct 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Queenstown-Escape-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Queenstown-Escape-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/Auckland-Escape-136x136-1.jpg',
                    name : '4 Day Auckland Escape',
                    price : '559',
                    complement : 'per person (twin share)',
                    code : 'ANZ244DAE',
                    location : 'Auckland (AKL)',
                    valid: '10 Jul 2024 - 31 Oct 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Auckland-Escape-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/4-Day-Auckland-Escape-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-Kapiti-Coast-136x136-1.jpg',
                    name : 'PurePod | 5 Day Kapiti Coast Escape',
                    price : '1499',
                    complement : 'per person (double share)',
                    code : 'ANZ5DKCE',
                    location : 'Wellington (WLG)',
                    valid: '01 Jun 2024 - 26 Nov 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/5-Day-Kapiti-Coast-Escape-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-5-Day-Kapiti-Coast-Escape-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-Stewart-Island-136x136-1.jpg',
                    name : 'PurePod | 6 Day Remote Stewart Island',
                    price : '1599',
                    complement : 'per person (double share)',
                    code : 'ANZ6DRSI',
                    location : 'Invercargill (IVC)',
                    valid: '01 Jun 2024 - 27 Nov 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/6-Day-Stewart-Island-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-6-Day-Remote-Stewart-Island-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-Ticket-to-Cromwell-136x136-1.jpg',
                    name : 'PurePod | 5 Day Ticket to Cromwell',
                    price : '1699',
                    complement : 'per person (double share)',
                    code : 'ANZ5DTTC',
                    location : 'Queenstown (ZQN)',
                    valid: '01 Jun 2024 - 26 Nov 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/5-Day-Ticket-to-Cromwell-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-5-Day-Ticket-to-Cromwell-AUD-scaled.jpg'
                },
                {
                    thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-KeriKeri-Wonders-136x136-1.jpg',
                    name : 'PurePod | 5 Day KeriKeri Wonders ',
                    price : '1999',
                    complement : 'per person (double share)',
                    code : 'ANZ5DKW',
                    location : 'Auckland (AKL)',
                    valid: '01 Jun 2024 - 26 Nov 2024',
                    itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/5-Day-Kerikeri-Wonders-AUD.pdf',
                    sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/PurePod-5-Day-KeriKeri-Wonders-AUD-scaled.jpg'
                },
            ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  