<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/hero-snow-highway-1-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/new-zealand-s-snow-highway.png" alt="New Zealand’s Snow Highway" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Aoraki Mt Cook Ntl Park - Photo: Rob Suisted</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <v-layout row wrap>
                      <v-flex xs12 md12 py-0 my-0>
                        <span class="headline font-weight-medium kiwiGreen d-block mb-3">Introducing Aotearoa’s very own Snow Highway.</span>
                        <p>This all-new itinerary will deliver more of what you love about winter in New Zealand: accessible and fun skiing, warm hospitality and stunning scenery. Start by flying into <a href="/the-insider/5-reasons-to-discover-christchurch-and-the-central-south-island/">Ōtautahi Christchurch</a>, then drive to nearby Methven to ski Mt Hutt, then from here it’s off to explore the spectacular Mackenzie Region before capping it all off in style in Queenstown or Wanaka. Sound pretty good? Discover it for yourself with one of these epic, adventure-filled snow holidays.</p>
                    <p><b>Learn more from The Insider:</b> <a href="/the-insider/discover-new-zealands-snow-highway/">Discover New Zealand’s Snow Highway</a></p>
                      </v-flex>
                      <!--<v-flex xs12 md2 py-0 my-0 text-xs-center hidden-sm-and-down>
                        <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/anzcro-snow-dough-2022-192x190-v2.png" alt="Anzcro’s Snow Dough 2022" class="campaign-sticker kirra-early-bird" width="96" height="95" />
                        <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><b>SELL SNOW FOR YOUR CHANCE TO WIN A $1,000 TRAVEL CREDIT</b></small>
                      </v-flex>-->
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 px-5 pb-5 elevation-0">
                    <v-responsive :aspect-ratio="16/9">
                      <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/799311392?h=26022f1826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="NZ_SnowHighway_2023"></iframe></div>
                    </v-responsive>
                  </v-card>                
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (AUD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-sh-mt-hutt.jpg',
            name : '4 Nights Magic of Mt Hutt',
            price : '1109',
            complement : 'per person (twin share)',
            code : 'SH244NMMH',
            location : 'Christchurch (CHC)',
            valid: '01 Jul – 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/4-Night-Magic-of-Mt-Hutt-AUD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-sh-adv.jpg',
            name : '8 Nights Snow Highway Adventure',
            price : '2099',
            complement : 'per person (twin share)',
            code : 'SB248NTSH',
            location : 'Christchurch (CHC)',
            valid: '01 Jul – 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/8-Night-The-Snow-Highway-AUD-1.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-sh-lux.jpg',
            name : '8 Nights Luxury on the Snow Highway',
            price : '3429',
            complement : 'per person (twin share)',
            code : 'SH248NLSH',
            location : 'Christchurch (CHC)',
            valid: '01 Jul – 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/8-Night-Luxury-Snow-Highway-AUD-1.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-sh-fam.jpg',
            name : '8 Nights Family Snow Highway',
            price : '6639',
            complement : 'per family (2Ad+2Ch)',
            code : 'SH248NFESH',
            location : 'Christchurch (CHC)',
            valid: '01 Jul – 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/8-Night-Family-Snow-Highway-AUD-1.pdf'
          },
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
